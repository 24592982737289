import { BRANDS, CJS_BRAND } from '../lib/utils';
import { APP_CONSTANTS } from '../lib/utils/constants';
import { BookingType, BrandType, Config } from '../types/config';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';
import { PRODUCT_TYPES } from '../types/criteria';

export const bookingTypes: BookingType[] = [
	{
		id: '1',
		name: 'Flights',
		type: PRODUCT_TYPES.FLIGHT_ONLY,
		icon: 'icon-travel-plane',
		standalone: false,
		brand: CJS_BRAND.VAA
	},
	{
		id: '5',
		name: 'Reward flights',
		type: PRODUCT_TYPES.REWARD_FLIGHT,
		icon: 'icon-travel-plane',
		standalone: true,
		brand: CJS_BRAND.VAA
	},
	{
		id: '4',
		name: 'Single destination',
		type: PRODUCT_TYPES.HOLIDAY,
		icon: 'icon-travel-upgrade',
		standalone: false,
		brand: CJS_BRAND.VAH
	},
	{
		id: '6',
		name: 'Multi-destination',
		brand: CJS_BRAND.VAH,
		type: PRODUCT_TYPES.MULTICENTRE,
		icon: 'icon-travel-map-marker',
		standalone: false
	},
	{
		id: '3',
		name: 'FlyDrive',
		type: PRODUCT_TYPES.FLYDRIVE,
		icon: 'icon-travel-car-hire',
		standalone: false,
		brand: CJS_BRAND.VAH
	}
];

export const Brands: BrandType[] = [
	{
		name: 'Virgin Atlantic',
		displayName: 'Flights',
		brand: CJS_BRAND.VAA,
		icon: 'icon-travel-plane',
		defaultBookingType: PRODUCT_TYPES.FLIGHT_ONLY
	},
	{
		name: 'Virgin Holidays',
		displayName: 'Holidays',
		brand: CJS_BRAND.VAH,
		icon: 'icon-travel-suitcase',
		defaultBookingType: PRODUCT_TYPES.HOLIDAY
	}
];

export const config: Config = {
	niceName: 'Virgin Atlantic',
	heading: 'So, where next?',
	summary: {
		title: 'Where next?',
		button: 'Start a search',
		editButton: 'Edit search',
		ticker: {
			title: 'Search for',
			hasImages: true,
			actions: ['flights to', 'holidays in'],
			locations: [
				{
					name: 'New York',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/new-york-1.jpg`
				},
				{
					name: 'New York',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/new-york-2.jpg`
				},
				{
					name: 'Orlando',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/orlando-1.jpg`
				},
				{
					name: 'Orlando',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/orlando-2.jpg`
				}
			]
		}
	},
	bookingTypes: bookingTypes
};
