import { css, SerializedStyles } from '@emotion/react';

import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';

const {
	spacing: {
		size_4px: { height: size_4 }
	},
	color: colours
} = tokens;

export const StyledScrollbars = css`
	&::-webkit-scrollbar {
		width: ${size_4}px;
		position: absolute;
	}

	&::-webkit-scrollbar-track {
		background: ${colours.light.neutral_20};
	}

	&::-webkit-scrollbar-thumb {
		background: ${colours.light.neutral_60};

		&:hover {
			background: ${colours.light.neutral_70};
		}
	}
`;

export const NoGapSupport = (styles: () => SerializedStyles) => css`
	@supports (-webkit-touch-callout: none) and (not (translate: none)) {
		${styles()}
	}
`;
