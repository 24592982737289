import { css } from '@emotion/react';
import styled from '@emotion/styled';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';

import { BreakPointToken } from '../../hooks/useMediaQuery';

const {
	spacing: {
		size_2px: { height: size_2 },
		size_4px: { height: size_4 },
		size_8px: { height: size_8 },
		size_12px: { height: size_12 }
	}
} = tokens;

const dot = css`
	content: '';
	display: inline-block;
	width: ${size_4}px;
	height: ${size_4}px;
	border-radius: 100%;
	background-color: var(--token-color-colour-text-tertiary);
`;

export const SummaryPlayback = styled.div`
	width: 100%;
	text-align: left;
	margin-left: 20px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow: hidden;
	margin-right: ${size_12}px;

	h1 {
		@media (max-width: ${BreakPointToken.SmMax}px) {
			flex: 0 0 100%;
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&::after {
			@media (min-width: ${BreakPointToken.MdMin}px) {
				${dot}
				margin: ${size_2}px ${size_8}px;
			}
		}
	}

	p {
		white-space: nowrap;
		width: max-content;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span + span {
		&::before {
			${dot}
			margin: ${size_2}px ${size_8}px;
		}
	}
`;
