import { DateTime } from 'luxon';
import {
	FlightPartyComposition,
	RoomComposition
} from '../lib/utils/composition.service';
import { Airport, Location, Gateway } from './api';

export enum PRODUCT_TYPES {
	FLIGHT_ONLY = 'FLIGHT_ONLY',
	REWARD_FLIGHT = 'REWARD_FLIGHT',
	FLIGHT_HOTEL = 'FLIGHT_HOTEL',
	FLIGHT_CAR = 'FLIGHT_CAR',
	PACKAGES = 'PACKAGES',
	HOLIDAY = 'HOLIDAY',
	HOTEL = 'HOTEL',
	MULTICENTRE = 'MULTICENTRE',
	FLYDRIVE = 'FLYDRIVE',
	CAR = 'CAR'
}

export type Criteria =
	| FLIGHT_ONLY
	| REWARD_FLIGHT
	| FLIGHT_HOTEL
	| HOLIDAY
	| HOTEL
	| MULTICENTRE
	| FLYDRIVE
	| CAR;

export interface BaseCriteria {
	refs?: { [key: string]: string };
}

export interface FLIGHT_ONLY extends BaseCriteria {
	searchType: string;
	origin?: Airport[];
	destination?: Airport[];
	departing?: DateTime[];
	passengers?: FlightPartyComposition;
	discountCode?: string;
	cabin?: string;
	paymentType?: string;
	tripType?: string;
}

export interface REWARD_FLIGHT extends FLIGHT_ONLY {}

export interface CJS_GENERIC extends BaseCriteria {
	searchType?: string;
	location?: Location;
	departureDate?: DateTime;
	rooms?: RoomComposition[];
}

export interface HOTEL extends CJS_GENERIC {
	duration?: number;
}

export interface FLIGHT_HOTEL extends CJS_GENERIC {
	gateways?: Gateway[];
	gateway?: Gateway;
	duration?: number;
}

export interface HOLIDAY extends HOTEL {
	gateways?: Gateway[];
	gateway?: Gateway;
	duration?: number;
}

export interface MULTICENTRE extends CJS_GENERIC {}

export interface FLYDRIVE extends CJS_GENERIC {
	gateways?: Gateway[];
	gateway?: Gateway;
	duration?: number;
}

export interface CAR extends BaseCriteria {
	searchType: string;
	pickUpLocation: string;
	pickUpDate: DateTime;
	pickUpTime: DateTime;
	dropOffLocation: string;
	dropOffDate: DateTime;
	dropOffTime: DateTime;
}
