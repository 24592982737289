import React, { useEffect, useState } from 'react';
import { SummaryPlayback } from './summary-playback.component.styles';
import { useApplication } from '../../hooks/useApplication';
import {
	FLIGHT_HOTEL,
	FLIGHT_ONLY,
	HOLIDAY,
	PRODUCT_TYPES,
	REWARD_FLIGHT,
	HOTEL,
	MULTICENTRE,
	FLYDRIVE
} from '../../types/criteria';
import { DATE_FORMATS } from '../../lib/utils/constants';
import { Passengers, Rooms } from '../../lib/utils/composition.service';
import {
	TypographyComponent,
	TypographyVariant,
	TypographySize,
	TypographyWeight
} from '@vaa-component-lib/component.atom.typography';
import useMediaQuery, { BreakPointToken } from '../../hooks/useMediaQuery';

const SummaryPlaybackComponent: React.FC = () => {
	const { application, setApplication } = useApplication();
	const [title, setTitle] = useState<string>('');
	const [contents, setContents] = useState<string[]>([]);

	const isNotMobile = useMediaQuery(`(min-width: ${BreakPointToken.MdMin}px)`);

	const setFlightDates = (): string => {
		switch ((application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT)?.tripType) {
			case 'ONE_WAY':
				return `${(application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT).departing[0]?.toFormat(DATE_FORMATS.DISPLAY_DATE)}`;

			case 'ROUND_TRIP':
				const departingDates = (
					application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT
				).departing;
				const returnDate = departingDates[1]
					? ` - ${departingDates[1].toFormat(DATE_FORMATS.DISPLAY_DATE)}`
					: '';
				return `${departingDates[0]?.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)}${returnDate}`;
		}
	};

	useEffect(() => {
		switch (application?.criteria?.searchType) {
			case PRODUCT_TYPES.FLIGHT_ONLY:
			case PRODUCT_TYPES.REWARD_FLIGHT: {
				const flight_criteria = application?.criteria as
					| FLIGHT_ONLY
					| REWARD_FLIGHT;
				setTitle(
					`${application?.criteria?.searchType === PRODUCT_TYPES.REWARD_FLIGHT ? `Reward Flights` : `Flights`} to ${
						flight_criteria.destination[0]?.cityName
					}`
				);
				const contents = [
					flight_criteria.origin[0]?.airportCode
						? `From ${flight_criteria.origin[0].airportCode}`
						: null,
					setFlightDates() || null,
					Passengers.prettyFormat(flight_criteria?.passengers)
						? `${Passengers.prettyFormat(flight_criteria.passengers)}`
						: null
				].filter(Boolean);

				setContents(contents);

				break;
			}
			case PRODUCT_TYPES.FLIGHT_HOTEL:
			case PRODUCT_TYPES.HOLIDAY:
			case PRODUCT_TYPES.FLIGHT_CAR: {
				const flight_hotel_criteria = application?.criteria as
					| FLIGHT_HOTEL
					| HOLIDAY;
				setTitle(() => {
					switch (application?.criteria.searchType) {
						case PRODUCT_TYPES.FLIGHT_HOTEL:
							return `Flight + Hotel in ${flight_hotel_criteria.location?.locationDescription}`;
						case PRODUCT_TYPES.FLIGHT_CAR:
							return `Flight + Car`;
						case PRODUCT_TYPES.HOLIDAY:
							return `Holiday in ${flight_hotel_criteria.location?.locationDescription}`;
					}
				});
				const contents = [
					flight_hotel_criteria.gateway?.code
						? `From ${flight_hotel_criteria.gateway.code}`
						: null,
					flight_hotel_criteria.departureDate
						? `${flight_hotel_criteria.departureDate.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)} - ${flight_hotel_criteria.departureDate.plus({ days: flight_hotel_criteria?.duration }).toFormat(DATE_FORMATS.DISPLAY_DATE)}`
						: null,
					flight_hotel_criteria.rooms?.length
						? `${Rooms.totalPassengers(flight_hotel_criteria.rooms)} ${Rooms.totalPassengers(flight_hotel_criteria.rooms) > 1 ? 'people' : 'person'} / ${flight_hotel_criteria.rooms.length} ${flight_hotel_criteria.rooms.length === 1 ? 'room' : 'rooms'}`
						: null
				].filter(Boolean);

				setContents(contents);

				break;
			}
			case PRODUCT_TYPES.HOTEL: {
				const hotel_criteria = application?.criteria as HOTEL;
				setTitle(`Hotel in ${hotel_criteria.location?.locationDescription}`);
				const contents = [
					hotel_criteria.departureDate
						? `${hotel_criteria.departureDate.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)} - ${hotel_criteria.departureDate.plus({ days: hotel_criteria?.duration }).toFormat(DATE_FORMATS.DISPLAY_DATE)}`
						: null,
					hotel_criteria.rooms?.length
						? `${Rooms.totalPassengers(hotel_criteria.rooms)} ${Rooms.totalPassengers(hotel_criteria.rooms) > 1 ? 'people' : 'person'} / ${hotel_criteria.rooms.length} ${hotel_criteria.rooms.length === 1 ? 'room' : 'rooms'}`
						: null
				].filter(Boolean); // Filters out null or undefined values

				setContents(contents);

				break;
			}
			case PRODUCT_TYPES.MULTICENTRE: {
				const multicentre_criteria = application?.criteria as MULTICENTRE;
				setTitle(
					`Multi-destination in ${multicentre_criteria?.location?.locationDescription}`
				);
				const contents = [
					multicentre_criteria.departureDate
						? `${multicentre_criteria.departureDate.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)}`
						: null,
					multicentre_criteria.rooms?.length
						? `${Rooms.totalPassengers(multicentre_criteria.rooms)} ${Rooms.totalPassengers(multicentre_criteria.rooms) > 1 ? 'people' : 'person'} / ${multicentre_criteria.rooms.length} ${multicentre_criteria.rooms.length === 1 ? 'room' : 'rooms'}`
						: null
				].filter(Boolean); // Filters out null or undefined values

				setContents(contents);

				break;
			}

			case PRODUCT_TYPES.FLYDRIVE: {
				const flydrive_criteria = application?.criteria as FLYDRIVE;
				setTitle(
					`FlyDrive in ${flydrive_criteria?.location?.locationDescription}`
				);

				const contents = [
					flydrive_criteria.gateway?.code
						? `From ${flydrive_criteria.gateway.code}`
						: null,
					flydrive_criteria.departureDate
						? `${flydrive_criteria.departureDate.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)} - ${flydrive_criteria.departureDate.plus({ days: flydrive_criteria?.duration }).toFormat(DATE_FORMATS.DISPLAY_DATE)}`
						: null,
					,
					Rooms.prettyFormat(flydrive_criteria?.rooms)
						? `${Rooms.prettyFormat(flydrive_criteria.rooms)}`
						: null
				].filter(Boolean); // Filters out null or undefined values

				setContents(contents);

				break;
			}
		}
	}, []);

	if (application?.criteria === null) {
		return <></>;
	}

	return (
		<SummaryPlayback data-cy="playback-component">
			<TypographyComponent
				element={'h1'}
				data-cy="playback-title"
				variant={TypographyVariant.Body}
				size={isNotMobile ? TypographySize.Mdm : TypographySize.Sml}
				weight={TypographyWeight.Medium}>
				{title}
			</TypographyComponent>
			<TypographyComponent
				element={'p'}
				data-cy="playback-content"
				variant={TypographyVariant.Body}
				size={isNotMobile ? TypographySize.Mdm : TypographySize.Xsm}
				weight={TypographyWeight.Regular}>
				{contents.map((content, i) => (
					<span key={i}>{content}</span>
				))}
			</TypographyComponent>
		</SummaryPlayback>
	);
};

export default SummaryPlaybackComponent;
