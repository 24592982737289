import { memo, useEffect, useRef, useState } from 'react';

import { SummaryConfig } from '../../types/config';

import shuffleArray from '../../lib/utils/shuffle-array';
import useMediaQuery, { BreakPointToken } from '../../hooks/useMediaQuery';

import {
	TypographyComponent,
	TypographyVariant,
	TypographySize,
	TypographyWeight
} from '@vaa-component-lib/component.atom.typography';
import {
	DestinationImage,
	DestinationPanel,
	DestinationTeaser,
	TickerContainer,
	TickerPanel,
	TickerText,
	TickerWrap
} from './summary-ticker.styles';

interface ShuffledItem {
	text: string;
	image?: string;
}

const SummaryTickerComponent = ({
	title: mainTitle,
	ticker: { title, hasImages, actions, locations }
}: SummaryConfig) => {
	const isDesktop = useMediaQuery(`(min-width: ${BreakPointToken.LgMin}px)`);

	const currentIndex = useRef<number>(0);
	const [shuffledState, setShuffledState] = useState<ShuffledItem[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [strings, setStrings] = useState<{
		current: string;
		next: string | null;
	}>({
		current: shuffledState[currentIndex.current]?.text || '',
		next: null
	});

	const [images, setImages] = useState<(string | undefined)[]>([]);
	const [animateTicker, setAnimateTicker] = useState<boolean>(false);
	const [animateImages, setAnimateImages] = useState<boolean>(false);

	const timing = 2500;

	const emptyImageArray = Array(4).fill(null);

	useEffect(() => {
		const combined = actions.flatMap((action) =>
			locations.map((location) => ({
				text: `${action} ${location.name}`,
				image: location.image
			}))
		);

		const shuffled = shuffleArray(combined);

		setShuffledState(shuffled);
		setTotalCount(shuffled.length);

		if (shuffled.length > 0) {
			setStrings({
				current: shuffled[0].text,
				next: shuffled[1]?.text || null
			});
		}
	}, [actions, locations]);

	useEffect(() => {
		if (totalCount === 0) return;

		const ticker = setInterval(() => {
			const { current: thisIndex } = currentIndex;
			const nextIndex = thisIndex >= totalCount - 1 ? 0 : thisIndex + 1;

			const nextText = shuffledState[nextIndex]?.text || '';

			setStrings((prevStrings) => ({
				current: prevStrings.current,
				next: nextText
			}));

			setAnimateTicker(true);
			currentIndex.current = nextIndex;

			const timeout = setTimeout(() => {
				setStrings(() => ({
					current: nextText,
					next: null
				}));
				setAnimateTicker(false);
			}, timing / 2);

			return () => clearTimeout(timeout);
		}, timing);

		return () => clearInterval(ticker);
	}, [shuffledState]);

	useEffect(() => {
		const totalLength = shuffledState.length;

		if (totalLength < 2 || !hasImages) {
			return;
		}

		const getShuffledImage = (index: number) =>
			(shuffledState[index % totalLength] as ShuffledItem).image;

		const startIndex = currentIndex.current % totalLength;
		const orderedImages = Array.from({ length: 4 }, (_, i) =>
			getShuffledImage(startIndex + i)
		);

		setAnimateImages(true);

		const imageryTimeout = setTimeout(() => {
			setAnimateImages(false);
			setImages(orderedImages);
		}, timing / 3);

		return () => clearTimeout(imageryTimeout);
	}, [currentIndex.current, shuffledState]);

	const textConfig = {
		variant: TypographyVariant.Body,
		size: isDesktop ? TypographySize.Mdm : TypographySize.Xsm,
		weight: TypographyWeight.Regular,
		element: 'p',
		style: {
			display: 'flex',
			gap: '0.3em',
			alignItems: 'stretch',
			flex: 'auto'
		}
	};

	return (
		<TickerPanel {...{ hasImages }} data-cy={'summary-ticker'}>
			{hasImages && (
				<DestinationTeaser data-cy={'ticker-carousel'}>
					{emptyImageArray.map((_, i) => (
						<DestinationPanel
							key={`destinationPanel-${i}`}
							number={i + 1}
							animate={animateImages && !!images.length}
							timing={animateImages ? timing : 0}
							data-cy={'carousel-panel'}>
							{!!images.length && (
								<DestinationImage
									alt={strings.current}
									src={images[i]}
									onLoad={(
										e: React.SyntheticEvent<HTMLImageElement, Event>
									) => {
										e.currentTarget.style.opacity = '1';
									}}
									number={i + 1}
									{...{ timing }}
									data-cy={'carousel-image'}
									aria-hidden="true"
								/>
							)}
						</DestinationPanel>
					))}
				</DestinationTeaser>
			)}
			<TickerContainer>
				<TypographyComponent
					element="h1"
					variant={TypographyVariant.Body}
					size={isDesktop ? TypographySize.Mdm : TypographySize.Sml}
					weight={TypographyWeight.Medium}
					data-cy={'ticker-title'}>
					{mainTitle}
				</TypographyComponent>
				<TypographyComponent
					{...textConfig}
					element="div"
					data-cy={'ticker-text'}
					aria-hidden="true">
					{title}{' '}
					<TickerWrap>
						<TickerText
							timing={animateTicker ? timing : 0}
							state={animateTicker ? 'old' : null}
							secondary={false}
							data-cy={'ticker-first-span'}>
							{strings.current}
						</TickerText>
						<TickerText
							timing={animateTicker ? timing : 0}
							state={animateTicker ? 'new' : null}
							secondary={true}>
							{strings.next && strings.next}
						</TickerText>
					</TickerWrap>
				</TypographyComponent>
			</TickerContainer>
		</TickerPanel>
	);
};

export default memo(SummaryTickerComponent);
