import { BRANDS } from '.';
import { FlightPartyComposition, RoomComposition } from './composition.service';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';

const {
	spacing: {
		size_24px: { height: size_24 },
		size_48px: { height: size_48 }
	}
} = tokens;

export const APP_CONSTANTS = {
	BASE: '/search-experience',
	ASSETS_PATH: '/assets/public'
};

export const STATE_DEFAULTS = {
	BRAND: BRANDS.VAA,
	OPEN: false,
	CLOSABLE: true,
	HIDE_TOGGLE: false,
	FLOAT: false
};

export const DATE_FORMATS = {
	URL_DATE: 'yyyy-MM-dd',
	CJS_URL_DATE: 'dd-MM-yyyy',
	DISPLAY_DATE: 'dd MMM, yyyy',
	FIELD_DISPLAY_DATE: 'dd MMM',
	CALENDAR_DAY: 'd',
	CALENDAR_MONTH: 'MMMM'
};

export const CABIN_CLASSES = {
	ALL_CABINS: {
		code: '',
		name: 'All Cabins',
		displayName: 'All cabins'
	},
	ECONOMY: {
		code: 'VSLT',
		name: 'Economy',
		displayName: 'Economy'
	},
	PREMIUM: {
		code: 'VSPE',
		name: 'Premium',
		displayName: 'Premium'
	},
	UPPER: {
		code: 'VSUP',
		name: 'Upper Class',
		displayName: 'Upper Class'
	}
};

export const TRIP_TYPES = {
	ONE_WAY: {
		value: 'ONE_WAY',
		name: 'One way',
		displayName: 'One way trip'
	},
	ROUND_TRIP: {
		value: 'ROUND_TRIP',
		name: 'Round trip',
		displayName: 'Round trip'
	},
	MULTICITY: {
		value: 'MULTICITY',
		name: 'Multi-city',
		displayName: 'Multi-city'
	}
};

export const PAYMENT_TYPES = {
	MONEY: {
		value: '',
		name: 'Money',
		displayName: 'Pay with money'
	},
	POINTS: {
		value: 'PAY_WITH_POINTS',
		name: 'Points plus money',
		displayName: 'Pay with points plus money'
	}
};

export const NOTCH_HEIGHT = size_48;

export const TICKER_HEIGHTS = {
	default: '92px',
	small: '122px'
};

export const BACKGROUND_HEIGHTS = {
	default: {
		closed: '148px',
		opened: '148px'
	},
	desktop: {
		closed: TICKER_HEIGHTS.small,
		opened: `calc(100% + ${size_24}px)`
	}
};

export const DEFAULT_PASSENGERS: FlightPartyComposition = {
	adults: 1,
	teens: 0,
	children: 0,
	infants: 0
};

export const DEFAULT_ROOMS: RoomComposition[] = [
	{
		adults: 2,
		childAges: [],
		infants: 0
	}
];

// TODO: revisit once we've solidified stacking tokens from CL
// We have to start at 1007 to ensure we sit above header and footer...!
export const Z_INDICES = {
	base: 1007,
	popover: 6,
	popverBlanket: 5
};

export const EVENTS = {
	flyoutOpen: 'OPEN_FLYOUT',
	flyoutClose: 'CLOSE_FLYOUT',
	searchPanelBrandChange: 'SEARCH_PANEL_BRAND_CHANGE'
};

export const DATEPICKER_ERROR_MESSAGE = {
	noSelection: 'You need to add departure and return dates',
	oneWay: 'Please enter your flight date',
	range: 'When are you coming back?',
	duration: 'Maximum 28 nights'
};

export const DATE_MODIFIERS = {
	defaultStartDays: 0,
	defaultEndMonths: 12,
	holidaysStartDays: 3,
	holidaysEndMonths: 24
};
