export const findRefs = (
	params: URLSearchParams
): { [key: string]: string } => {
	const refs: { [key: string]: string } = {};

	params.forEach((value, key) => {
		if (key.startsWith('ref:')) {
			const refKey = key.split('ref:')[1];
			refs[refKey] = value;
		}
	});

	return refs;
};

export const refsToUrlParams = (refs: { [key: string]: string }): string => {
	if (!refs) {
		return '';
	}

	let urlParams = '';

	Object?.keys(refs)?.forEach((key) => {
		const value = refs[key];
		urlParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
	});

	return urlParams;
};
