const triggerCustomEvent = (name: string, payload?: any): void => {
	const customEvent = new CustomEvent(name, {
		bubbles: true,
		cancelable: true,
		detail: payload || null
	});

	document.body.dispatchEvent(customEvent);
};

export default triggerCustomEvent;
