import { createContext, useContext } from 'react';

export type BlanketType = {
	show: boolean;
	// Event used to override default Blanket on-click functionality
	onClick?: () => void | null;
};

export type BlanketContextType = {
	blanket: BlanketType;
	setBlanket: (blanket: any) => void;
};

export const BlanketContext = createContext<BlanketContextType>({
	blanket: {
		show: false
	},
	setBlanket: () => (blanket: BlanketType) =>
		console.warn('no blanket provider')
});
