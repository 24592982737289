import {
	ButtonColour,
	ButtonComponent,
	ButtonPriority,
	ButtonSize,
	ButtonType
} from '@vaa-component-lib/component.atom.button';
import {
	IconActionCrossComponent,
	IconTravelPlaneComponent,
	IconTravelSuitcaseComponent
} from '@vaa-component-lib/component.atom.icons';
import { ContainerComponent } from '@vaa-component-lib/component.layout.container';
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useApplication } from '../../hooks/useApplication';
import { useBlanket } from '../../hooks/useBlanket';
import useMediaQuery, { BreakPointToken } from '../../hooks/useMediaQuery';
import { BookingType, BrandType, Config } from '../../types/config';
import { PRODUCT_TYPES } from '../../types/criteria';
import {
	BrandToggle,
	BrandToggleListItem,
	CloseButton,
	InnerPanel,
	SaleFlashOuter,
	Heading,
	SearchContainer,
	SearchExperience,
	BackgroundPanel
} from './search-experience.styles';
import { bookingTypes, Brands } from '../../config/config';
import {
	TypographyComponent,
	TypographyVariant,
	TypographySize,
	TypographyWeight
} from '@vaa-component-lib/component.atom.typography';
import triggerCustomEvent from '../../lib/utils/trigger-custom-event';
import { EVENTS } from '../../lib/utils/constants';

const FlightOnlyComponent = lazy(() => import('../forms/flight.component'));
const HolidayComponent = lazy(() => import('../forms/holiday.component'));
const FlightCarComponent = lazy(() => import('../forms/flight-car.component'));
const MulticentreComponent = lazy(
	() => import('../forms/multicentre.component')
);

interface SearchExperienceComponentProps {
	config: Config;
	shouldAnimate?: boolean;
	initialOpenState?: boolean;
}

const SearchExperienceComponent = ({
	config,
	shouldAnimate = false,
	initialOpenState = true
}: SearchExperienceComponentProps) => {
	const { application, setApplication } = useApplication();
	const { blanket, setBlanket } = useBlanket();
	const [locale, setLocale] = useState<string>('en-GB');
	let defaultBookingType: BookingType = config?.bookingTypes[0];

	if (application?.criteria?.searchType !== undefined) {
		defaultBookingType = config?.bookingTypes?.find(
			(item) => item?.type === application?.criteria?.searchType
		);
	}

	const [selectedBookingType, setSelectedBookingType] =
		useState<BookingType>(defaultBookingType);
	const [isBlock, setIsBlock] = useState<boolean>(false);
	const [isShown, setIsShown] = useState<boolean>(false);
	const topRef = useRef<HTMLDivElement>(null);

	const isTablet = useMediaQuery(`(min-width: ${BreakPointToken.MdMin}px)`);

	const changeSelectedBookingType = (b: BookingType) => {
		setSelectedBookingType(b);
		triggerCustomEvent(EVENTS.searchPanelBrandChange, { bookingType: b.type });
	};

	const mapIcon = (icon: string) => {
		switch (icon) {
			case 'icon-travel-plane':
				return <IconTravelPlaneComponent />;
			case 'icon-travel-suitcase':
				return <IconTravelSuitcaseComponent />;
		}
	};

	const closeExperience = () => {
		setBlanket({ ...blanket, show: false });
		setApplication({ ...application, open: false });
	};

	useEffect(() => {
		// This is used to ensure that the document doesn't jump horizontally each time the scrollbars are
		// shown or hidden (for example: when Blanket or the Menu is triggered)
		if (document) {
			triggerCustomEvent(EVENTS.searchPanelBrandChange, {
				bookingType: selectedBookingType.type
			});
			const documentEl = document.documentElement;
			Object.assign(documentEl.style, {
				'overflow-x': 'hidden',
				'margin-right': 'calc(-1 * (100vw - 100%))'
			});
		}
	}, []);

	useEffect(() => {
		setLocale(
			navigator.languages && navigator.languages.length
				? navigator.languages[0]
				: navigator.language || 'en-GB'
		);
	}, []);

	useEffect(() => {
		setLocale(
			navigator.languages && navigator.languages.length
				? navigator.languages[0]
				: navigator.language || 'en-GB'
		);
	}, [selectedBookingType]);

	useEffect(() => {
		let animationTimer: NodeJS.Timeout;
		let overflowTimer: NodeJS.Timeout;

		const { open } = application;

		const animateInTime = shouldAnimate ? 25 : 0;
		const animateOutTime = shouldAnimate ? 400 : 0;

		if (open) {
			setIsBlock(true);
			animationTimer = setTimeout(() => {
				setIsShown(true);

				if (!initialOpenState) {
					topRef.current.focus();
				}
			}, animateInTime);
		} else {
			setIsShown(false);
			animationTimer = setTimeout(() => setIsBlock(false), animateOutTime);
		}

		overflowTimer = setTimeout(() => {
			if (document) {
				const currentlyTablet = window.matchMedia(
					`(min-width: ${BreakPointToken.MdMin}px)`
				).matches;

				const documentEl = document.documentElement;
				const hideOverflow =
					(open && !currentlyTablet) ||
					(open && !currentlyTablet && !initialOpenState);

				documentEl.style.overflow = hideOverflow ? 'hidden' : '';
				document.body.style.overflow = hideOverflow ? 'hidden' : '';
			}
		}, animateOutTime * 2);

		return () => {
			clearTimeout(animationTimer);
		};
	}, [application]);

	return (
		<SearchExperience
			data-cy="search-experience-component"
			visible={isTablet ? isBlock : isShown}
			animate={shouldAnimate}
			role="region">
			<BackgroundPanel show={isShown} animate={shouldAnimate} />
			<ContainerComponent as="section">
				<InnerPanel
					show={isShown}
					animate={shouldAnimate}
					initiallyOpen={initialOpenState}>
					<Heading
						className={application.hideToggle === true ? 'toggle-hidden' : ''}
						ref={topRef}>
						{application.heading && (
							<TypographyComponent
								variant={TypographyVariant.Heading}
								size={TypographySize.Mdm}
								weight={TypographyWeight.Regular}
								element="h1"
								data-cy={'application-heading'}
								aria-label={`${application.heading}, begin your search...`}>
								{application.heading}
							</TypographyComponent>
						)}
					</Heading>

					{application.hideToggle !== true && (
						<BrandToggle show={isShown} animate={shouldAnimate}>
							{Brands.map((brand: BrandType, idx: number) => {
								return (
									<BrandToggleListItem
										show={isShown}
										key={idx}
										data-testid="brand-toggle">
										{brand?.brand === selectedBookingType?.brand && (
											<SaleFlashOuter id={`sales_flash_${brand?.brand}`}>
												<TypographyComponent
													variant={TypographyVariant.Body}
													size={TypographySize.Xsm}
													weight={TypographyWeight.Medium}
													element="span">
													<></>
												</TypographyComponent>
											</SaleFlashOuter>
										)}
										<ButtonComponent
											text={brand.displayName}
											size={ButtonSize.Medium}
											iconChild={mapIcon(brand.icon)}
											priority={ButtonPriority.Selector}
											colour={ButtonColour.ContrastSecondary}
											isActive={brand?.brand === selectedBookingType?.brand}
											data-cy={`brand-toggle-${brand.brand}`}
											onClick={() =>
												changeSelectedBookingType(
													bookingTypes.find(
														(b) => b.type === brand.defaultBookingType
													)
												)
											}
											// @ts-ignore
											tabIndex={isShown ? 0 : -1}
										/>
									</BrandToggleListItem>
								);
							})}
						</BrandToggle>
					)}

					<SearchContainer
						className={application.hideToggle === true ? 'toggle-hidden' : ''}
						show={isShown}
						animate={shouldAnimate}>
						<Suspense>
							{[
								PRODUCT_TYPES.FLIGHT_ONLY,
								PRODUCT_TYPES.REWARD_FLIGHT
							].includes(selectedBookingType.type) && (
								<FlightOnlyComponent bookingType={selectedBookingType} />
							)}
							{[
								PRODUCT_TYPES.FLIGHT_HOTEL,
								PRODUCT_TYPES.HOLIDAY,
								PRODUCT_TYPES.HOTEL
							].includes(selectedBookingType.type) && (
								<HolidayComponent
									bookingType={selectedBookingType}
									selectSubBookingType={changeSelectedBookingType}
								/>
							)}
							{selectedBookingType.type === PRODUCT_TYPES.FLYDRIVE && (
								<FlightCarComponent
									bookingType={selectedBookingType}
									selectSubBookingType={changeSelectedBookingType}
								/>
							)}
							{selectedBookingType.type === PRODUCT_TYPES.MULTICENTRE && (
								<MulticentreComponent
									bookingType={selectedBookingType}
									selectSubBookingType={changeSelectedBookingType}
								/>
							)}
						</Suspense>
					</SearchContainer>
					{(application?.closable || !isTablet) && (
						<CloseButton show={isShown}>
							<ButtonComponent
								onClick={() => closeExperience()}
								priority={ButtonPriority.Tertiary}
								colour={ButtonColour.Inverse}
								size={ButtonSize.Small}
								type={ButtonType.Icon}
								aria-label="Close search panel"
								iconChild={<IconActionCrossComponent />}
								data-cy={'close-button'}
								// @ts-ignore
								tabIndex={isShown ? 0 : -1}
							/>
						</CloseButton>
					)}
				</InnerPanel>
			</ContainerComponent>
		</SearchExperience>
	);
};

export default SearchExperienceComponent;
