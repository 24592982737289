import { createContext, useContext } from 'react';
import { State } from '../types/state';

export type ApplicationContextType = {
	application: State;
	setApplication: (application: any) => void;
};

export const ApplicationContext = createContext<ApplicationContextType>({
	application: {
		open: false,
		closable: true,
		hideToggle: false,
		float: false
	},
	setApplication: () => (application: State) =>
		console.warn('no application provider')
});
