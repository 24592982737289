import { Config } from '../../types/config';
import {
	Background,
	SearchIcon,
	SearchLabel,
	SummaryTrigger
} from './summary.styles';
import { useApplication } from '../../hooks/useApplication';
import { useBlanket } from '../../hooks/useBlanket';
import useMediaQuery, { BreakPointToken } from '../../hooks/useMediaQuery';
import { useTheme } from '@emotion/react';

import { ContainerComponent } from '@vaa-component-lib/component.layout.container';
import {
	TypographyComponent,
	TypographyVariant,
	TypographySize,
	TypographyWeight
} from '@vaa-component-lib/component.atom.typography';
import SummaryTickerComponent from '../summary-ticker/summary-ticker.component';
import SummaryPlaybackComponent from '../summary-playback/summary-playback.component';
import {
	IconActionSearchComponent,
	IconsSize
} from '@vaa-component-lib/component.atom.icons';
import CriteriaService from '../../lib/utils/criteria.service';

interface SummaryComponentProps {
	config: Config;
}

const SummaryComponent = ({ config }: SummaryComponentProps) => {
	const { application, setApplication } = useApplication();
	const { blanket, setBlanket } = useBlanket();
	const theme = useTheme();

	const openExperience = () => {
		setBlanket({ ...blanket, show: true });
		setApplication({ ...application, open: true });
	};

	const { summary: summaryConfig } = config;

	const isMidSize = useMediaQuery(`(min-width: ${BreakPointToken.MdMin}px)`);
	const isDesktop = useMediaQuery(`(min-width: ${BreakPointToken.LgMin}px)`);

	return (
		<Background
			float={application?.float}
			theme={theme}
			data-cy="summary-component"
			data-float={application?.float}
			role="region">
			<ContainerComponent as="section">
				<SummaryTrigger
					data-cy="summary-trigger"
					onClick={() => openExperience()}
					visible={!application?.open}
					tabIndex={application?.open ? -1 : 0}>
					{CriteriaService.validateCriteriaCanShowPlayback(
						application?.criteria
					) ? (
						<SummaryPlaybackComponent />
					) : (
						<SummaryTickerComponent {...summaryConfig} />
					)}

					<SearchLabel data-cy="summary-search-label">
						<TypographyComponent
							variant={TypographyVariant.Body}
							size={isDesktop ? TypographySize.Mdm : TypographySize.Sml}
							weight={TypographyWeight.Medium}>
							{application?.criteria != null
								? summaryConfig.editButton
								: summaryConfig.button}
						</TypographyComponent>
					</SearchLabel>
					<SearchIcon data-cy="summary-search-icon">
						<IconActionSearchComponent
							size={isMidSize ? IconsSize.Med : IconsSize.Sml}
						/>
					</SearchIcon>
				</SummaryTrigger>
			</ContainerComponent>
		</Background>
	);
};

export default SummaryComponent;
