import SearchExperienceContainer from '../src/components/search-experience.container';
import { hydrateRoot } from 'react-dom/client';
import '../src/styles/globals.css';
import UrlService from '../src/lib/utils/url.service';
import CriteriaService from '../src/lib/utils/criteria.service';
import { config } from '../src/config/config';
import { parseJSON } from '../src/lib/utils';

(async () => {
	// Dynamically grab the url from the server request to allow the ESI params to work
	const initialData = parseJSON(window.SearchPanelInitialData);
	const serverUrl = decodeURIComponent(initialData.trim());
	const urlService = new UrlService(serverUrl);
	const brand = urlService.getBrand();
	const criteriaService = new CriteriaService(serverUrl, config);
	const state = urlService.getState();
	const criteria = await criteriaService.getCriteria();

	const root = document.getElementById('search-experience-root');

	if (null !== root) {
		hydrateRoot(root, <SearchExperienceContainer state={state} criteria={criteria} />);
	}
})();
